import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, concatMap, delayWhen, mergeMap, retryWhen, timeout } from 'rxjs/operators';
import { AuthStateModel } from 'src/app/store/auth/auth.model';
import { AuthState } from 'src/app/store/auth/auth.state';
import { HTTP_TIMEOUT } from './app.constants';
import { originalError, originalRequest } from './error-monitoring.service';

// Each status code can retry with a different delay
const retryStatusCodes = {
  0: 1000,
  429: 1000,
  500: 1000,
  502: 1000,
  503: 1000,
  504: 5000,
  511: 1000,
};
const retryCount = 1;

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private store: Store,
    @Inject(HTTP_TIMEOUT) private httpTimeout: number,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO: add headers only for own API urls
    if (!req.url.startsWith('/')) {
      const auth: AuthStateModel = this.store.selectSnapshot(AuthState);
      let params = req.params;

      const companyId = auth?.company?.id;
      if (companyId && !params.has('company')) { params = params.set('company', companyId); }
      if (!params.get('company')) { params = params.delete('company'); }

      const token = auth?.token;
      const setHeaders = {};
      if (token && !req.headers.has('Authorization')) {
        setHeaders['Authorization'] = `JWT ${token}`;
      }

      req = req.clone({ params, setHeaders });
    }

    const httpRequest = next.handle(req).pipe(
      timeout(this.httpTimeout),
      retryWhen(errors => errors
        .pipe(
          concatMap((error, count) => {
            const delayTime = retryStatusCodes[error.status];
            if (delayTime && delayTime > 0 && count < retryCount) {
              return of(delayTime);
            }

            return throwError(() => error);
          }),
          delayWhen(time => timer(time)),
        ),
      ),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          const res = err.error || {};
          if (typeof res === 'object') {
            res[originalError] = err;
            res[originalRequest] = req;
          }
          return throwError(() => res);
        }
        return throwError(() => err);
      }),
      mergeMap((event) => {
        if (event instanceof HttpResponse) {
          if (event.body?.error) {
            const res = event.body || {};
            if (typeof res === 'object') {
              res[originalError] = event;
              res[originalRequest] = req;
            }
            return throwError(() => res);
          }
        }
        return of(event);
      }),
    );
    return httpRequest;
  }
}

export const httpInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpInterceptorService,
  multi: true,
};
