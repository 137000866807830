import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { of, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isTimeDoctor } from 'src/app/app/app.constants';
import { AddCreditCardStep } from 'src/app/components/starter-sidebar/items/add-credit-card';
import { NewBillingState } from 'src/app/pages/new-billing/store/state';
import { BrandingService } from 'src/app/services/branding.service';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})

export class TrialWidgetService {
  constructor(
    private store: Store,
    private branding: BrandingService,
  ) { }

  canShowTrialWidget() {
    const billingDetails$ = this.store.select(NewBillingState.updatedBillingDetails);

    return this.store.select(AuthSelectors.company).pipe(
      switchMap((company) => {
        return !company?.id ? of(false) : billingDetails$.pipe(
          filter((billingDetails) => !!billingDetails?.customerStatus),
          map(({ customerStatus }) => {
            const { userSettings, role, id } = company;
            const isCreditCardAdded = userSettings?.custom?.completedStep?.[AddCreditCardStep.stepId];
            const isCompanyInTrial = customerStatus === 'trial';
            const hasBillingAccess = !this.branding.billingDisabled() && (role === 'owner' || userSettings?.billingAccess);

            return !isTimeDoctor(id) && isCompanyInTrial && hasBillingAccess && !isCreditCardAdded;
          }),
        );
      }),
    );
  }
}
