import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Event as NavigationEvent, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { AuthState } from 'src/app/store/auth/auth.state';
import { TrialWidgetState } from 'src/app/store/trial-widget/trial-widget.state';
import { AuthCompany, AuthUser } from 'src/models';
import { filter } from 'rxjs/operators';
import { noSideBarUrls } from 'src/app/components/nav-sidebar/nav-sidebar.component';
import { NewBillingState } from 'src/app/pages/new-billing/store/state';
import { BillingDetailsApiData } from 'src/app/pages/new-billing/types/api.types';

@UntilDestroy()
@Component({
  selector: 'app-trial-widget',
  templateUrl: './trial-widget.component.html',
  styleUrls: ['./trial-widget.component.scss'],
})
export class TrialWidgetComponent implements OnInit {
  @Select(AuthState.company) company$: Observable<AuthCompany>;
  @Select(NewBillingState.billingMode) billingMode$: Observable<BillingDetailsApiData['billingMode']>;

  @Input()
  set isCollapsed(willBeCollapsed: boolean) {
    if (!willBeCollapsed && this._isCollapsed) {
      this.trackWidgetViewed();
    }

    this._isCollapsed = willBeCollapsed;
  }

  get isCollapsed() { return this._isCollapsed; }

  @Input() variant: 'raised' | 'outlined' = 'raised';

  private _isCollapsed: boolean;

  trialDaysLeft = 0;
  trialStartDate: DateTime;
  trialEndDate: DateTime;
  currentUrl: string;
  user: AuthUser;

  @HostBinding('class.outlined') get outlined() {
    return this.variant === 'outlined';
  }

  @HostBinding('class.collapsed') get collapsed() {
    return this.isCollapsed;
  }

  get btnClickTrackData() {
    return {
      page: 'Clicked Upgrade On Trial Widget',
      trialstartdate: this.trialStartDate?.toFormat('yyyy-MM-dd'),
      trialenddate: this.trialEndDate?.toFormat('yyyy-MM-dd'),
      currenturl: this.currentUrl,
    };
  }

  constructor(
      private router: Router,
      private store: Store,
      private segment: SegmentService,
      private growthbookService: GrowthBookService,
  ) {
    this.user = this.store.selectSnapshot(AuthState.user);

    let shouldDispatch = false;

    this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate' || event.restoredState) {
          shouldDispatch = true;
        }
      } else if (event instanceof NavigationEnd) {
        shouldDispatch = true;
        this.currentUrl = event.url;
      }
    });

    router.events.pipe(
      untilDestroyed(this),
      filter((event) => event instanceof NavigationEnd
        && !noSideBarUrls.some((url) => event.urlAfterRedirects.includes(url)),
      ),
      filter(() => !this.isCollapsed),
    ).subscribe(() => {
      this.trackWidgetViewed();
    });
  }

  private trackWidgetViewed() {
    this.segment.trackInAppEvents('Trial Widget Viewed', {
      status: 'trial',
      version: this.store.selectSnapshot(TrialWidgetState.getTrialWidgetType),
    });
  }

  ngOnInit() {
    this.displayTrialDays();
  }

  private async displayTrialDays() {
    this.company$
      .pipe(untilDestroyed(this))
      .subscribe(company => {
        if (company) {
          this.trialStartDate = DateTime.fromISO(company.companyCreatedAt);
          this.trialEndDate = DateTime.fromISO(company.subscription.expires);
          this.trialDaysLeft = Math.floor(this.trialEndDate.diff(DateTime.now(), 'days').days);
        }
      });
  }

  async trialUpgrade() {
    const currentPath = this.router.url;
    const subscription = this.store.selectSnapshot(AuthState.company)?.subscription;

    const path = subscription?.provider === 'paddle'
      ? '/new-subscribe-plans'
      : '/subscribe-plans';

    this.router.navigate([path], {queryParams: {url: encodeURI(currentPath)}});
  }
}
