<ng-container *ngIf="!isCollapsed">
  <div class="trial-box" fxLayout="column" *ngIf="variant === 'raised'; else outlinedVariant">
    <div class="trial-title">
      {{ 'time.duration.#day' | translate: {count: trialDaysLeft} }} {{ 'sidebar.widget.title' | translate }}
    </div>
    <div class="trial-subtitle">{{ 'sidebar.widget.subtitle' | translate }}</div>
    <div class="trial-description">{{ 'sidebar.widget.description' | translate }}</div>
    <button
      *ngIf="(billingMode$ | async) !=='invoicing'"
      [trackClick]="btnClickTrackData"
      mat-flat-button class="trial-button" (click)="trialUpgrade()">{{ 'sidebar.widget.button' | translate | uppercase
      }}</button>
  </div>
</ng-container>

<ng-template #outlinedVariant>
  <span class="icon-wrapper">
    <mat-icon class="material-icons-outlined">timer</mat-icon>
  </span>
  <h6 class="typography-body2 bold">
    {{ 'time.duration.#day' | translate: { count: trialDaysLeft } }} {{ 'sidebar.widget.title' | translate }} {{ 'sidebar.widget.subtitle' | translate }}
  </h6>
  <p class="typography-caption">{{ 'sidebar.widget.description' | translate }}</p>
  <button
    *ngIf="(billingMode$ | async) !=='invoicing'"
    mat-stroked-button color="primary"
    (click)="trialUpgrade()"
    [trackClick]="btnClickTrackData"
  >
    {{ 'sidebar.widget.button' | translate | uppercase }}
  </button>
</ng-template>
