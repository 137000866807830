import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ERROR_URL } from 'src/app/app/app.constants';
import { EndOfTrialService } from 'src/app/services/end-of-trial/end-of-trial.service';
import { AuthState } from 'src/app/store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class EndOfTrialGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    private trialer: EndOfTrialService,
    @Inject(ERROR_URL) private errorUrl,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot) {
    const currentPath = route.url[0].path;
    const company = this.store.selectSnapshot(AuthState.company);
    const trialer = await this.trialer.isTrialler(company);

    if (trialer && currentPath.includes('billing')) {
      return this.router.createUrlTree(['/end-of-trial']);
    }
    if (!trialer && !currentPath.includes('billing')) {
      return  this.router.createUrlTree(['/billing']);
    }
    return true;
  }
}
