<div fxLayout="row" fxLayoutAlign="center center">
  <mat-icon *ngIf="icon" [color]="iconColor">{{ icon }}</mat-icon>
  <mat-icon *ngIf="svgIcon" [svgIcon]="svgIcon" [color]="iconColor"></mat-icon>
  <div class="header-text">
    <h1>
      <ng-content select="[banner-header]"></ng-content>
    </h1>
    <p>
      <ng-content select="[banner-subheader]"></ng-content>
    </p>
  </div>
</div>

<div style="flex: 0 1000 90px; min-width: 10px; max-height: 10px;"></div>

<div class="header-buttons" style="flex: 0 1 auto;">
  <ng-content select="[banner-buttons]"></ng-content>
</div>
