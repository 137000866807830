import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ApiService } from './api.service';
import {
  GOOGLE_CALLBACK_URI,
  GOOGLE_CLIENT_ID,
  TDC_CHECK_EMAIL_END_POINT
} from './app.constants';
import { DataIntegrityTrackingService } from './data-integrity-tracking.service';

type GsiButtonConfiguration = {
  locale?: string;
  shape?: 'rectangular' | 'pill' | 'circle' | 'square';
  size?: 'large' | 'medium' | 'small';
  text?: 'continue_with' | 'signin_with' | 'signup_with' | 'signin';
  theme?: 'filled_blue' | 'filled_black' | 'outline';
  type?: 'standard' | 'icon';
  width?: number;
  auto_select?: boolean;
};

type CredentialAuthResponse = {
  isNewUser?: boolean;
  data?: {
    createdAt?: string;
    expiresAt?: string;
    token?: string;
  };
  error?: string;
  message?: string;
};

type ScriptLoadResult = { error?: Error };

const OAUTH_ENDPOINT = 'oauth2/google/auth';
const GOOGLE_SCRIPT_SRC = 'https://accounts.google.com/gsi/client';
const GOOGLE_SCRIPT_ID = 'google';

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {
  private _credentialResponse = new BehaviorSubject<{ credential?: string } | null>(null);
  private hasCallbackUri = !!this.googleCallbackUri;

  constructor(
    @Inject(GOOGLE_CLIENT_ID) private googleClientId: string,
    @Inject(TDC_CHECK_EMAIL_END_POINT) private tdcCheckEmailEndPoint: string,
    @Inject(GOOGLE_CALLBACK_URI) private googleCallbackUri: string,
    private api: ApiService,
    private dataIntegrityTracking: DataIntegrityTrackingService,
  ) { }

  private async loadScript(id: string, src: string): Promise<ScriptLoadResult> {
    if (typeof document === 'undefined') {
      return { error: new Error('Document is not available') };
    }

    if (document.getElementById(id)) {
      return {};
    }

    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.id = id;
      script.async = true;
      script.src = src;
      script.onload = () => resolve({});
      script.onerror = () => resolve({ error: new Error(`Failed to load script with src: ${src}`) });
      document.head.appendChild(script);
    });
  }


  private getSettings() {
    const baseSettings = {
      client_id: this.googleClientId,
    };

    const desktopSettings = {
      ux_mode: 'redirect',
      login_uri: this.googleCallbackUri,
      response_type: 'token',
    };

    const webSettings = {
        callback: response => this._credentialResponse.next(response),
    };

    return this.hasCallbackUri
        ? { ...baseSettings, ...desktopSettings }
        : { ...baseSettings, ...webSettings };
  }

  private b64DecodeUnicode(str: string): string {
    return decodeURIComponent(Array.prototype.map.call(atob(str), c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
  }

  parseJwt(token: string): any {
    const decodedString = this.b64DecodeUnicode(
      token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/'),
    );
    return JSON.parse(decodedString);
  }

  async initialize(): Promise<void> {
    await this.loadScript(GOOGLE_SCRIPT_ID, GOOGLE_SCRIPT_SRC);

    const google = window['google'];
    if (google?.accounts?.id?.initialize) {
      google.accounts.id.initialize(this.getSettings());
      this.renderButton();
    }
  }

  logout(): void {
    this._credentialResponse.next(null);
  }

  renderButton(): void {
    const innerWidth = window.innerWidth;
    const width = innerWidth <= 490 ? (innerWidth - 110) : 400;
    const gsiButtonConfiguration: GsiButtonConfiguration = {
      size: 'large',
      text: 'signin_with',
      width,
      locale: 'en',
      auto_select: true,
    };
    window['google'].accounts.id.renderButton(
      document.getElementById('btnGoogleSignIn'),
      gsiButtonConfiguration,
    );
  }

  credential() {
    return this._credentialResponse;
  }

  emailExistInTDC(email: string) {
    if (!this.tdcCheckEmailEndPoint || this.hasCallbackUri) return null;
    const headers = { params: { email }, headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    return lastValueFrom(this.api.request('post', this.tdcCheckEmailEndPoint + '/check_email', {}, headers));
  }

  async authRequest(credential: any): Promise<CredentialAuthResponse> {
    const payload = this.parseJwt(credential);
    const userEmail = payload['email'];
    if (!userEmail) return null;
    this.trackLoginSuccess(userEmail);
    const body = {
      email: userEmail,
      timezone: Intl && Intl.DateTimeFormat().resolvedOptions().timeZone,
      splitTest: [
        { name: 'popup-signup-trigger', value: 'login-form' },
        { name: 'google-auth', value: true },
      ],
    };
    const authHeader = {
      headers: {
        Authorization: `JWT ${credential}`,
      },
    };

    return lastValueFrom(this.api.request('post', OAUTH_ENDPOINT, body, authHeader));
  }

  private trackLoginSuccess(email: string): void {
    const props = {
      homecta: 'login-form',
      email,
    };
    this.dataIntegrityTracking.track('Login Page', 'Successful Google Authentication', props, null, 'track');
  }
}
